<div class="app-container">
  <div class="site postproduction">
    <div class="pagecontainer">
      <div class="page page-fullwidth">
        <div class="wrapper">
          <div class="wrapper relative iframecontainer">
            <div class="overlay"></div>
            <div class="imageplaceholder"></div>
            <div id="main-iframe-container">
              <iframe
                id="iframe"
                src="https://player.vimeo.com/video/760807327?h=88464ee24e&amp;badge=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=1&amp;autoplay=0&amp;loop=0&amp;muted=1&amp;controls=0"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                style="width: 100%; height: 90%"
                title=""
                data-ready="true"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="onpage maincontent">
    <div class="wrapper maintext">
      <div class="row">
        <div class="col">
          <h1 class="maintitle white">Essentials</h1>
          <p class="mainp white">Essentially video</p>
        </div>
      </div>
      <div class="row group-btns01">
        <div class="col-md-auto buton buton01">
          <div class="btn btn00 info">
            <div class="linksplitscrolla item white opacity0">
              <a
                class="lateanimate"
                href="https://www.youtube.com/watch?v=k__SknAcbEU&list=PL7nVIFXeQw5PBxrxITCJ8XusVYCTK1qEv"
                target="_blank"
                ><div class="splita">+ EPISODES</div></a
              >
            </div>
          </div>
        </div>
        <div class="col-md-auto buton buton02">
          <div
            onclick="reel('https://player.vimeo.com/video/1001557879?h=88464ee24e')"
            class="btn btn01"
          >
            <div class="linksplitscrolla item white">
              <a class="lateanimate"><div class="splita">PLAY VIDEO</div></a>
            </div>
          </div>
        </div>
        <div class="col-md-auto">
          <div id="circleProgress" class="circleP">
            <div class="circlecontainer relative">
              <div class="wrapper wrapper01">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
                  <circle
                    cx="17"
                    cy="17"
                    r="15.5"
                    class="countdown__background"
                  />
                  <circle cx="17" cy="17" r="15.5" class="countdown__load" />
                  <circle
                    cx="17"
                    cy="17"
                    r="15.5"
                    class="countdown__progress js-countdown__progress"
                  />
                </svg>
              </div>
            </div>
            <div class="textP">
              <div class="wrapper">
                <div class="txt text01">Loading...</div>
                <div class="txt text02">Close video</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
