<nav id="nav">
    <div class="clickMobiles"></div>
    <div class="content">
        <div class="wrapper wrapper01 padd-t-3 padd-b-0">
            <div class="logo-section padd-b-5">
                <a class="href" [attr.data-target]="'home'">
                    <div class="wrapper">
                            <svg class="logo" id="Capa_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 302.57 93.51"><g id="Capa_1-2"><g>
                                <path d="M97.27,0l-19.62,35.07-.54,.96-3.21,5.75-3.76,6.72-3.76-6.72-3.21-5.75-.54-.96L43,0H11.69C5.23,0,0,5.23,0,11.69V29.22H35.07v11.69H0v40.91c0,6.46,5.23,11.69,11.69,11.69h23.38V51.73l3.76,6.71,19.62,35.07h23.38l19.62-35.07,3.76-6.71v41.78h23.38c6.46,0,11.69-5.23,11.69-11.69V11.69c0-6.45-5.24-11.69-11.69-11.69h-31.31Z"/>
                                <g class="hidelogo"><path d="M163.54,18.45h5.29v22.87h-5.29V18.45Z"/><path d="M193.47,31.51c.74,.97,1.11,2.16,1.11,3.58,0,2-.78,3.54-2.34,4.62-1.56,1.08-3.83,1.62-6.81,1.62h-11.83V18.45h11.17c2.79,0,4.93,.53,6.42,1.6,1.49,1.07,2.24,2.52,2.24,4.35,0,1.11-.27,2.1-.8,2.97-.53,.87-1.28,1.56-2.24,2.06,1.31,.41,2.33,1.11,3.07,2.07Zm-14.61-9.07v5.39h5.26c1.31,0,2.3-.23,2.97-.69,.67-.46,1.01-1.13,1.01-2.03s-.34-1.56-1.01-2.01c-.68-.45-1.67-.67-2.97-.67h-5.26Zm9.33,14.21c.71-.46,1.06-1.17,1.06-2.12,0-1.89-1.4-2.84-4.21-2.84h-6.18v5.65h6.18c1.39,0,2.44-.23,3.15-.69Z"/><path d="M216.31,37.08v4.25h-17.71V18.45h17.29v4.25h-12.03v4.97h10.62v4.12h-10.62v5.29h12.45Z"/><path d="M235.1,41.32l-4.41-6.37h-4.87v6.37h-5.29V18.45h9.9c2.03,0,3.78,.34,5.28,1.01,1.49,.67,2.64,1.63,3.45,2.87,.81,1.24,1.21,2.71,1.21,4.41s-.41,3.16-1.23,4.4c-.82,1.23-1.98,2.17-3.48,2.83l5.13,7.35h-5.69Zm-1.34-17.53c-.83-.69-2.04-1.03-3.63-1.03h-4.31v7.97h4.31c1.59,0,2.8-.35,3.63-1.05,.83-.7,1.24-1.68,1.24-2.94s-.41-2.27-1.24-2.96Z"/><path d="M244.54,18.45h5.29v22.87h-5.29V18.45Z"/><path d="M270.98,36.42h-10.62l-2.03,4.9h-5.42l10.19-22.87h5.23l10.23,22.87h-5.55l-2.03-4.9Zm-1.67-4.02l-3.63-8.76-3.63,8.76h7.25Z"/><path d="M302.57,18.45v22.87h-4.35l-11.4-13.89v13.89h-5.23V18.45h4.38l11.37,13.89v-13.89h5.23Z"/></g>
                                <g class="hidelogo"><path d="M182.77,74.04l-.03-12.68-6.22,10.45h-2.2l-6.19-10.17v12.41h-4.59v-21.13h4.04l7.91,13.13,7.79-13.13h4.02l.06,21.13h-4.59Z"/><path d="M208.13,70.12v3.92h-16.36v-21.13h15.97v3.92h-11.11v4.59h9.81v3.8h-9.81v4.89h11.5Z"/><path d="M212.33,52.91h9.6c2.29,0,4.32,.44,6.08,1.31,1.76,.88,3.13,2.11,4.11,3.7,.98,1.59,1.46,3.44,1.46,5.55s-.49,3.97-1.46,5.56c-.98,1.59-2.34,2.82-4.11,3.7s-3.79,1.31-6.08,1.31h-9.6v-21.13Zm9.36,17.12c2.11,0,3.8-.59,5.06-1.77,1.26-1.18,1.89-2.77,1.89-4.79s-.63-3.61-1.89-4.78c-1.26-1.18-2.94-1.77-5.06-1.77h-4.47v13.1h4.47Z"/><path d="M237.05,52.91h4.89v21.13h-4.89v-21.13Z"/><path d="M262.29,69.51h-9.81l-1.87,4.53h-5.01l9.42-21.13h4.83l9.45,21.13h-5.13l-1.87-4.53Zm-1.54-3.71l-3.35-8.09-3.35,8.09h6.7Z"/></g></g></g></svg>
                    </div>
                </a>
            </div>
            <div class="items-section">
                <div class="wrapper wrapper02">
                    <div class="item item01">
                        <a class="href" [attr.data-target]="'postproduction'">
                            <div class="wrapper">
                                <div class="number">01</div>
                                <div class="text">Post production</div>
                            </div>
                        </a>
                    </div>
                    <div class="item item01">
                        <a class="href" [attr.data-target]="'filming'">
                            <div class="wrapper">
                                <div class="number">02</div>
                                <div class="text">Filming</div>
                            </div>
                        </a>
                    </div>
                    <div class="item item01">
                        <a class="href" [attr.data-target]="'voicesfy'">
                            <div class="wrapper">
                                <div class="number">03</div>
                                <div class="text">Voicesfy</div>
                            </div>
                        </a>
                    </div>
                    <div class="item item01">
                        <a class="href" [attr.data-target]="'excellence'">
                            <div class="wrapper">
                                <div class="number">04</div>
                                <div class="text">Excellence</div>
                            </div>
                        </a>
                    </div>
                    <div class="item item01">
                        <a class="href" [attr.data-target]="'contact'">
                            <div class="wrapper">
                                <div class="number">05</div>
                                <div class="text">Contact</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>    
    </div>
    <div class="progress">
        <div class="progress01"></div>
    </div>


</nav>

<div id="mainoverlay"></div>
<div id="initoverlay"></div>
<div id="videoOverlay"></div>

<div class="sound">
    <div class="wrapper row">
        <!-- <div class="text white col">Sound on</div> -->
        <div class="bars col-md-auto">
            <div class="wrapper">
                <div class="bar bar01"></div>
                <div class="bar bar02"></div>
                <div class="bar bar03"></div>
            </div>
        </div>
    </div>
    <div class="tooltip">
        <span>Disable audio</span>
    </div>
</div>




<div class="init">
    <div class="wrapper">
        <div class="section section1 opacity0 padd-b-3">
            <svg class="logo" id="Capa_2" xmlns="http://www.w3.org/2000/svg" viewBox="-30 0 202.57 93.51"><g id="Capa_1-2"><g>
                <path d="M97.27,0l-19.62,35.07-.54,.96-3.21,5.75-3.76,6.72-3.76-6.72-3.21-5.75-.54-.96L43,0H11.69C5.23,0,0,5.23,0,11.69V29.22H35.07v11.69H0v40.91c0,6.46,5.23,11.69,11.69,11.69h23.38V51.73l3.76,6.71,19.62,35.07h23.38l19.62-35.07,3.76-6.71v41.78h23.38c6.46,0,11.69-5.23,11.69-11.69V11.69c0-6.45-5.24-11.69-11.69-11.69h-31.31Z"/></g></g></svg>
        </div>
        <div class="section section2">
            <div class="wrapper">
                <!-- <div class="logoheadphones opacity0">
                    
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
<g>
   <path d="M256,31C115.39,31,0,145.39,0,286v120c0,24.814,20.186,45,45,45h15V301H45c-5.284,0-10.285,1.082-15,2.763V286
       C30,161.928,131.928,61,256,61s226,100.928,226,225v17.763c-4.715-1.681-9.716-2.763-15-2.763h-15v150h15
       c24.814,0,45-20.186,45-45V286C512,145.39,396.61,31,256,31z"/>
</g>
</g>
<g>
<g>
   <path d="M135,271h-15c-16.569,0-30,13.431-30,30v150c0,16.569,13.431,30,30,30h15c8.284,0,15-6.716,15-15V286
       C150,277.716,143.284,271,135,271z"/>
</g>
</g>
<g>
<g>
   <path d="M392,271h-15c-8.284,0-15,6.716-15,15v180c0,8.284,6.716,15,15,15h15c16.569,0,30-13.431,30-30V301
       C422,284.431,408.569,271,392,271z"/>
</g>
</g>
</svg>

                </div> -->
                <div class="txtheadphones">
                    Global ad studios
                </div>
                <div class="btns">
                    <div class="row group-btns01 padd-t-2">
                        <!-- <div onclick="initClick()" class="col-md-auto buton buton01">
                            <div class="btn btn00">
                                <div class="linksplitscrolla item white opacity0" ><a class="lateanimate"><div class="splita">ENTER WEBSITE </div></a></div>
                            </div>
                        </div> -->
                        
                    </div>        
                </div>
            </div>
        </div>
    </div>
</div>