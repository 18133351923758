import { Component, OnInit } from '@angular/core';
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from '@angular/router';
import Observer from 'gsap/Observer';
import { gsap } from 'gsap';
import * as $ from 'jquery';

declare var textsOnInitIMPLEMENTATION: any;
declare var textsOnInit: any;
declare var navDisabledIMPLEMENTATION: any;
declare var navDisabled: any;
declare var hoverbtn01: any;
declare var vimmeoIMPLEMENTATION: any;
declare var contactPageOnInit: any;
declare var hoverNav: any;
declare var fadeVolumeOut: any;
declare var sound: any;
declare var modalJS: any;
declare var modalJSHovers: any;
declare var endVideo: any;
declare var audios: any;
declare var init: any;

if (typeof window !== 'undefined') {
  gsap.registerPlugin(Observer);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  currentRoute: string;
  currentUrl: string;
  static routeCurrent: any;

  constructor(public router: Router) {
    this.currentRoute = '';
    // DEBUG
    // this.currentUrl = 'http://localhost:4200/';
    // this.currentUrl = `https://ventiacapital.com/im-ago-24/`;
    this.currentUrl = `https://iberian.media/`;

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
      }
      if (event instanceof NavigationEnd) {
        // new audios();

        new textsOnInitIMPLEMENTATION();
        new textsOnInit('in');
        new hoverbtn01();
        new modalJSHovers();

        this.routeCurrent = window.location.href;

        if (this.routeCurrent !== `${this.currentUrl}contact`) {
          new vimmeoIMPLEMENTATION();
        }

        let loaded = gsap.timeline({});
        loaded.to($('#mainoverlay'), { opacity: 0.2 }, 0.25);
        if ($('.page').hasClass('transition')) {
          // loaded.to($('.page'), 1, {scale:1}, .15);
          $('.page').removeClass('transition');
        }

        const modals = Array.from(document.getElementsByClassName('info'));
        modals.forEach((modal) => {
          modal.addEventListener('click', () => {
            new modalJS('open');
          });
        });
      }
    });
  }

  routeCurrent: string = '';

  myroutes = ['/', 'essentials', 'contact'];

  title = 'IberianMedia';

  // START Transiciones entre páginas //
  // START Transiciones entre páginas //

  pageTransitions = (fromclick, stop) => {
    let currentIndex = -1;
    let animating;

    let gotoSection = (index, direction, origin, nextpage) => {
      if (
        (origin == 'scroll' &&
          direction == 'up' &&
          this.routeCurrent == this.currentUrl) ||
        (origin == 'scroll' &&
          direction == 'down' &&
          this.routeCurrent == `${this.currentUrl}contact`)
      ) {
        return;
      } else {
        new textsOnInit('out');
        if (this.isMobile() == false) {
          new navDisabled('in', 'kill');
        } else {
          new navDisabled('out', '', 'mobile');
        }
        new modalJS('close');
        if (this.routeCurrent !== `${this.currentUrl}contact`) {
          new fadeVolumeOut();
          new endVideo();
        } else {
          new contactPageOnInit('out');
        }

        hoverNav = false;

        var target = '';
        animating = true;
        let tl = gsap.timeline({
          defaults: { duration: 1.5, ease: 'Expo.Out' },
          onComplete: () => (animating = false),
        });

        if (origin == 'scroll') {
          if (direction == 'down') {
            var dir = '+=16.66666vh';
          } else if (direction == 'up') {
            dir = '-=16.66666vh';
          }
        } else {
          if (nextpage == 'home') {
            dir = this.progressHeights.home;
          } else if (nextpage == 'essentials') {
            dir = this.progressHeights.essentials;
          } else if (nextpage == 'contact') {
            dir = this.progressHeights.contact;
          }
        }
        tl.to($('#nav .progress01'), { duration: 0.8, height: dir }, 0),
          tl.to($('#mainoverlay'), 0.5, { opacity: 1 }, 0.45),
          tl.to(
            $(
              '.countdown__background, .countdown__load, .countdown__progress, .textP '
            ),
            0.5,
            { opacity: 0 },
            0
          );
        $('.page').addClass('transition');

        currentIndex = index;

        const calcNextPage = () => {
          if (origin == 'scroll') {
            if (this.routeCurrent == `${this.currentUrl}`) {
              if (direction == 'down') {
                target = this.myroutes[1];
              } else {
                target = this.myroutes[2];
              }
            } else if (this.routeCurrent == `${this.currentUrl}essentials`) {
              if (direction == 'down') {
                target = this.myroutes[2];
              } else {
                target = this.myroutes[0];
              }
            } else if (this.routeCurrent == `${this.currentUrl}contact`) {
              if (direction == 'down') {
                target = this.myroutes[0];
              } else {
                target = this.myroutes[1];
              }
            }
          } else {
            if (nextpage == 'home') {
              target = this.myroutes[0];
            } else if (nextpage == 'essentials') {
              target = this.myroutes[1];
            } else if (nextpage == 'contact') {
              target = this.myroutes[2];
            }
          }
          this.router.navigate([target]);
        };

        calcNextPage();
      }
    };

    if (fromclick) {
      !animating && gotoSection(currentIndex - 1, '', 'click', fromclick);
    }

    var tolValue = 100;
    var typeScroll = 'wheel, touch';
    if (this.isMobile()) {
      typeScroll = 'wheel, touch';
      tolValue = 250;
    } else {
      typeScroll = 'wheel';
      tolValue = 200;
    }

    Observer.create({
      type: typeScroll,
      wheelSpeed: -1,
      onDown: () =>
        !animating && gotoSection(currentIndex - 1, 'up', 'scroll', ''),
      onUp: () =>
        !animating && gotoSection(currentIndex - 1, 'down', 'scroll', ''),
      tolerance: tolValue,
      preventDefault: true,
    });
  };

  isMobile() {
    if ($(window).width() > 1060) {
      return false;
    } else {
      return true;
    }
  }

  progressHeights = {
    home: '33.3333vh',
    essentials: '50vh',
    contact: '66.6667vh',
  };

  // END Transiciones entre páginas //
  // END Transiciones entre páginas //

  initialHeight = 'initial';

  ngOnInit(): void {
    this.routeCurrent = window.location.href;

    let calculateInitialHeight = () => {
      if (this.routeCurrent == `${this.currentUrl}`) {
        this.initialHeight = this.progressHeights.home;
      } else if (this.routeCurrent == `${this.currentUrl}essentials`) {
        this.initialHeight = this.progressHeights.essentials;
      } else if (this.routeCurrent == `${this.currentUrl}contact`) {
        this.initialHeight = this.progressHeights.contact;
      }
      gsap.set($('#nav .progress01'), { height: this.initialHeight });
    };
    calculateInitialHeight();

    const buttons = Array.from(document.getElementsByClassName('href'));
    buttons.forEach((btn) => {
      btn.addEventListener('click', () => {
        let vv = $(btn).attr('data-target');

        if (
          (vv == 'home' && this.routeCurrent == `${this.currentUrl}`) ||
          (vv == 'essentials' &&
            this.routeCurrent == `${this.currentUrl}essentials`) ||
          (vv == 'contact' && this.routeCurrent == `${this.currentUrl}contact`)
        ) {
          return;
        } else {
          this.pageTransitions(vv, '');
        }
      });
    });

    const mainvideo = Array.from(document.getElementsByClassName('mainvideo'));
    mainvideo.forEach((btn) => {
      btn.addEventListener('click', () => {
        const image = document.getElementById(
          'iframe'
        ) as HTMLImageElement | null;
        if (image !== null) {
          image.src += '?autoplay=1';
        }
      });
    });

    this.pageTransitions('', '');

    new init();
    new navDisabledIMPLEMENTATION();
    // new sound('on');
  }
}
